#navBtns {
    display: flex;
    margin: 0 auto;
    height: 50px;
    position: relative;
    top: 10px;
    align-items: flex-end;
    justify-content: flex-end;
    padding-right: 10%;
    gap: 15px;
}

.dropdownMenu {
    position: absolute;
    top: 52px;
    width: clamp(100px, calc(5.5vw + 56px), 120px);
    transform: translateX(-3%);
    border-radius: 0 0 5% 5%;
    background-color: rgba(255, 255, 255, 0.856);
    overflow: hidden;
    transition: max-height 200ms ease-in-out, padding 200ms ease-in-out;
}

.close {
    max-height: 0;
    padding-top: 0;
    padding-bottom: 0;
}

.open {
    max-height: 300px;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    border-color: #ffffff;
    border-style: solid;
}

.menuItem {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 0.8rem;
    padding-right: 0.8rem;
    transition: background 500ms;
}

.menuItem:hover {
    background-color: rgba(37, 49, 102, 0.384);
}

.active {
    font-size: clamp(1rem, calc(1vw + 0.6rem), 1.3rem);
    text-decoration: none;
    margin: 1px;
    color: #b67237;
    animation: rotate 0.4s;
    font-weight: 600;
}

.inactive {
    font-size: clamp(0.95rem, calc(1vw + 0.6rem), 1.3rem);
    text-decoration: none;
    margin: 1px;
    box-sizing: border-box;
    color: #585858;
}

.top{
    font-size: clamp(0.95rem, calc(1vw + 0.6rem), 1.3rem);
    text-decoration: none;
    margin: 1px;
    box-sizing: border-box;
    color: #ffffff;
}

@media only screen and (max-width: 830px) {
    .dropdownMenu {
        top: 56px;
        width: clamp(100px, calc(5.5vw + 76px), 120px);
    }

    .active{
        font-size: clamp(1.2rem, calc(1.5vw + 0.7rem), 1.3rem);
    }

    .inactive{
        font-size: clamp(1.2rem, calc(1.5vw + 0.7rem), 1.3rem);
    }
}