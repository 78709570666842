@font-face {
    font-family: Barcode;
    src: url('../Fonts/LibreBarcode128Text-Regular.ttf');
    font-style: normal;
}

.profileName {
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
    color: rgb(86, 67, 87);
    font-family: Arial, Helvetica, sans-serif;
    font-size: clamp(16px, calc(2.5vw + 4px), 25px);
    
}

.profileText {
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;
    color: dimgray;
    font-size: clamp(15px, calc(1.5vw + 4px), 19px);
    margin-bottom: 10%;
}
.profileTextLink {
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;
    color: dimgray;
    font-size: clamp(15px, calc(1.5vw + 4px), 19px);
    text-decoration: none;
}

.profileTextLink:hover {
    animation: 0.1s size;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

@keyframes size {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(1.05);
        color: #4b433d;
    }
}

.profileImage {
    background-color: #bea7db;
    aspect-ratio: 1/1;
    display: block;
    max-width: 200px;
    width: 80%;
    border: #9989ad solid 1px;
    border-radius: 3px;
    margin: 20px auto 10px;
    filter: saturate(80%) hue-rotate(-10deg);
}


.profileContainer {
    justify-content: center;
    background-color: white;
    border-radius: 10px;
    display: block;
    margin: 5px auto;
    width: clamp(150px, calc(30% + 10px), 250px);
    box-shadow:  10px 10px 10px  0px rgba(89, 46, 145, 0.692);
}

@media only screen and (max-width: 748px) {
    .profileContainer {
        justify-content: center;
        background-color: white;
        border-radius: 10px;
        display: block;
        margin: 5px auto;
        width: clamp(150px, calc(40% + 10px), 250px);
        box-shadow:  10px 10px 10px  0px rgba(89, 46, 145, 0.692);
    }
}


@media only screen and (max-width: 360px) {
    .profileContainer {
        width: 132px;
    }

    .profileName {
        padding-left: 5px;
        padding-right: 5px;
    }
    
    .profileText {
        padding-left: 5px;
        padding-right: 5px;
    }

    .profileImage {
        margin: 15px auto 10px;
    }
}
