.heading2 {
    color: white;
    text-align: center;
    position: relative;
    top: 0px;
    font-weight: bold;
}

#body {
    position: relative;
    margin-left: 10%;
    margin-right: 10%;
    padding-bottom: 90px;
    margin-top: 10px;
}

.introBlock {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.introText {
    text-align: center;
    width: 20vw;
    min-width: 100px;
    max-width: 200px;
}

.introImg {
    width: 20vw;
    min-width: 100px;
    max-width: 200px;
}

#profileFlex {
    margin: auto;
    margin-top: 2%;
    margin-bottom: 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-content: flex-start;
    padding: 0;
    gap: 15px;
    width: 100%;
}

.aboutText {
    padding: 15px 20px;
    margin-bottom: 1rem;
    margin-top: 2%;
    background-color: rgba(255, 255, 255, 0.096);
    border-radius: min(2.5vw, 10px);
    color: white;
    margin-left: 8%;
    margin-right: 8%;
    font-weight: 100;
    text-wrap: pretty;
    font-family: Arial, Helvetica, sans-serif;
}

.aboutP {
    font-size: clamp(1rem, calc(2.2vw), 1.2rem);
    text-wrap: pretty;
    text-align: center;
}

.aboutLink {
    color: white;
    text-decoration: underline;
}

.aboutLink:hover {
    color: white;
    transform: scale(1.03);
    color: #e6ccb5;
}

.aboutText p:nth-last-child(1) {
    margin-bottom: 0;
}

.monkeyInfo2 {
    font-weight: 100;
}

@keyframes cardAnim {
    0% {
        transform: scale(1.5);
    }
    100% {
        transform: scale(1.1);
    }
}

@media only screen and (max-width: 450px) {
    #body {
        margin: 0px 5% 0px;
    }
}

@media only screen and (max-width: 830px) { 
    .aboutText {
        margin-left: 5%;
        margin-right: 5%;
        padding: 15px 20px 20px;
    }
}
