body {
  position: relative;
  min-height: 100vh;
  min-width: 310px;
  /* The next two lines are for radial graident background */
  background-image: url(https://grainy-gradients.vercel.app/noise.svg);
  background-attachment: scroll;
  background-size: 800px 800px;
  -webkit-background-size: 800px 800px;
  -moz-background-size: 800px 800px;
  -o-background-size: 800px 800px;
  background-repeat: repeat;
  background-position: center;
  /* The next four lines are for image background */
  /* background-image: url('./Pages/Media/signup_WIP.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover; */
  padding-bottom: 10px;
  overflow-x: hidden;
  font-family: Arial, Helvetica, sans-serif;
}

/* This body after is for the radial gradient background */
body::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-image: radial-gradient(rgba(111, 64, 240, 0.75), 70%, rgba(52, 174, 255, 0.6));
  z-index: -2;
  font-family: Arial, Helvetica, sans-serif;
}

h1 {
  margin: 0;
}