#socialsBar {
    position: absolute;
    bottom: 0;
    background-color: white;
    box-shadow: inset 0px 12px 10px -10px rgb(93, 117, 136);
    margin-top: 90px;
    padding: 20px 0px;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    gap: clamp(10px, calc(2.5vw - 10px), 20px);
}

.logo {
    object-fit: contain;
    width: 10vw;
    max-width: 50px;
    min-width: 30px;
    max-height: 50px;
    min-height: 30px;
    filter: invert(20%)
            drop-shadow(2px 2px 0px rgb(189, 189, 189));
    
}

.socialLink {
    display: inline-block;
    text-decoration: none;
    font-size: 1.1rem;
    color: white;
}

#socialHead {
    margin: 0 auto;
    text-align: center;
    width: fit-content;
    color: #CCCCCC;
    text-shadow: 1px 1px 0px rgb(84, 84, 84);
    font-size: 1.4rem;
}