.video {
    display: block;
    margin: 10px auto 0;
    width: 52vw;
    min-width: min(400px, calc(100vw - 10px));
    max-width: 1100px;
    box-shadow:  10px 10px 10px  0px rgba(117, 46, 145, 0.692);
    border: white solid 10px;
    background-color: black;
    border-radius: 5px;
}

#spiderVid {
    aspect-ratio: 3/2;
    max-width: 900px;
}

#batmanVid {
    aspect-ratio: 16/9;
}

/* #spiderGun {
    user-select: none;
    position: relative;
    top: -75px;
    width: 15vw;
    z-index: -1;
    transform: rotate(90deg);
}

#spiderVillain {
    user-select: none;
    position: relative;
    top: -75px;
    float: right;
    transform: rotate(270deg);
    width: 15vw;
    z-index: -1;
} */

#banner {
    user-select: none;
    position: relative;
    bottom: 10px;
    height: 15vw;
    max-height: 140px;
    min-height: 100px;
    display: block;
    margin: 15px auto 5px;
}

table {
    margin: 20px auto 110px;
}

td {
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;
    color: #ffffff;
    font-size: large;
    transition: 0.06s ease-in-out;
}

td:hover {
    transform: scale(1.2);
}

#heading {
    color: white;
    text-align: center;
    margin-top: 15px;    
}

#ImageParent {
    text-align: center;
}

.screencapL {
    width: clamp(20%, calc(27vw - 5%), 25%);
    max-width: 27rem;
    position: absolute;
    background-color: black;
    border-radius: 5px;
    border: white solid 8px;
    left: clamp(2vw, calc(5% - 8vw), 2.4vw );
    box-shadow:  10px 10px 10px  0px rgba(117, 46, 145, 0.692);
}

.screencapR {
    width: clamp(20%, calc(27vw - 5%), 25%);
    max-width: 27rem;
    position: absolute;
    background-color: black;
    border-radius: 5px;
    border: white solid 8px;
    right: clamp(2vw, calc(5% - 8vw), 2.4vw );
    box-shadow:  10px 10px 10px  0px rgba(117, 46, 145, 0.692);
}

@media only screen and (max-width: 870px) {
    .screencapL{
        display: none;
    }

    .screencapR{
        display: none;
    }
    
}

@media only screen and (max-width: 470px) {
    td {
        font-size: 1rem;
    }
    
    .creditName {
        padding-left: 10px;
    }
    
    .creditSocial {
        padding-right: 10px;
    }
}
