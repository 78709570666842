@font-face {
    font-family: Jersey10;
    src: url('../Fonts/Jersey10-Regular.ttf');
}

@font-face {
    font-family: Amatic;
    src: url('../Fonts/AmaticSC-Regular.ttf');
    font-style: normal;
    
}

@font-face {
    font-family: Amatic-Bold;
    src: url('../Fonts/AmaticSC-Bold.ttf');
}

@font-face {
    font-family: Marker;
    src: url('../Fonts/PermanentMarker-Regular.ttf');
    font-style: normal;
}

@font-face {
    font-family: Barcode;
    src: url('../Fonts/LibreBarcode128Text-Regular.ttf');
    font-style: normal;
}

@font-face {
    font-family: 'ClashDisplay-Semibold';
    src: url('../Fonts/ClashDisplay/ClashDisplay-Semibold.otf');
    font-weight: 700;
    font-display: swap;
    font-style: normal;
}

@font-face {
    font-family: 'ClashDisplay-light';
    src: url('../Fonts/ClashDisplay/ClashDisplay-Regular.otf');
    font-weight: 700;
    font-display: swap;
    font-style: normal;
}

#navbar {
    position: relative;
    top: 0;
    width: 100%;
    z-index: 1;
    height: 80px;
    user-select: none;
    font-family: ClashDisplay-light;
}

#navbar::after {
    content: '';
    position: absolute;
    left: 10%;
    bottom: 15px;
    width: 80%;
    height: 3px;
    background-color: #ffffff;
}

#header {
    margin: 0;
}

#reanimated {
    position: absolute;
    left: 10%;
    top: clamp(1rem, calc(2.3rem - 1vw), 1.7rem);
    display: inline-block;
    margin-bottom: 2rem;
    margin-right: 10px;
    text-decoration: none;
    color: #ffffff;
    font-size: clamp(1.4rem, calc(1vw + 1.3rem), 2.2rem);
    z-index: 2;
    vertical-align: bottom;
    letter-spacing: 0px;
    font-weight: 700;
    font-family: ClashDisplay-Semibold;
}

#reanimated:hover{
    animation: font 0.8s;
    animation-iteration-count: infinite;
    animation-direction: forward;
    animation-timing-function: step-end;
}

a {
    display: inline-block;
    position: relative;
    margin: 0;
}

.active {
    font-size: clamp(1rem, calc(1vw + 0.6rem), 1.3rem);
    text-decoration: none;
    margin: 1px;
    color: #e6ccb5;
    animation: rotate 0.4s;
    font-weight: 600;
}

.inactive {
    font-size: clamp(0.95rem, calc(1vw + 0.6rem), 1.3rem);
    text-decoration: none;
    margin: 1px;
    box-sizing: border-box;
    color: #ffffff;
}

.inactive:hover {
    color: #e6ccb5;
    animation: size 0.1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

.dropdownToggle {
    font-size: clamp(0.95rem, calc(1vw + 0.6rem), 1.3rem);
    text-decoration: none;
    margin: 1px;
    box-sizing: border-box;
    color: #ffffff;
    background-color: #ffffff00;
    border: none;
}

.dropdownToggle:hover{
    font-size: clamp(0.95rem, calc(1vw + 0.6rem), 1.3rem);
    text-decoration: none;
    margin: 1px;
    box-sizing: border-box;
    background-color: #ffffff00;
    border: none;
    animation: size 0.1s;
    color: #e6ccb5;
    animation: size 0.1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

.dropdownToggle:active{
    background-color: #ffffff00;
    border: none;
}

.dropdownToggle:focus{
    background-color: #ffffff00;
    border: none;
}

.dropdownMenu {
    background-color: #ffffff63;
    border: none;
}


@keyframes rotate {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

@keyframes font {
	20% {
		font-family:Amatic;
        letter-spacing: 10px;
        font-weight: 100;

	}
    40% {
		font-family: Jersey10;
        letter-spacing: 6px;
	}
	60% {
		font-family: Marker;
        kerning: 0px;
	}
    80% {
        font-family: Righteous-Regular;
        kerning: 0px;
    }
}

@keyframes size {
	0% {
		transform: scale(1);
	}
    100%{
        transform: scale(1.1);
    }
}

#navBtns {
    display: flex;
    margin: 0 auto;
    height: 50px;
    position: relative;
    top: 10px;
    align-items: flex-end;
    justify-content: flex-end;
    padding-right: 10%;
    gap: 15px;
}

@media only screen and (max-width: 830px) {
    #reanimated {
        text-align: center;
        position: relative;
        top: 18px;
        left: 0;
        display: block;
    }

    #navBtns {
        /* display: none; */
        top: -30px;
        padding-right: 0;
        justify-content: center;
        gap: clamp(0px, calc(3vw - 3px), 15px);
    }
    
    #navbar::after {
        /* display: none; */
        content: '';
        position: absolute;
        left: max(calc(45% - 167px), 0px);
        bottom: clamp(7px, calc(40px - 5.5vw), 12px);
        width: min(100%, calc(335px + 10%));
    }

    #navbar {
        height: 100px;
        margin-bottom: 10px;
    }

    .active{
        font-size: clamp(1.2rem, calc(1.5vw + 0.7rem), 1.3rem);
    }

    .inactive{
        font-size: clamp(1.2rem, calc(1.5vw + 0.7rem), 1.3rem);
    }
}