.video {
    display: block;
    margin: 20px auto 15px;
    width: 50vw;
    min-width: 300px;
    max-width: 700px;
    aspect-ratio: 4/3;
    box-shadow:  10px 10px 10px  0px rgba(117, 46, 145, 0.692);
    border: white solid 10px;
    background-color: black;
    border-radius: 5px;
}

#banner {
    user-select: none;
    position: relative;
    bottom: 10px;
    height: 14vw;
    max-height: 120px;
    min-height: 80px;
    display: block;
    margin: 15px auto 5px;
}

h3 {
    font-size: clamp(0.8rem, calc(1.5vw + 9px), 1rem);
    font-weight: bold;
    text-align: center;
}

.signUpLink {
    color: white;
    font-weight: bold;
    font-size: xx-large;
    /* padding: 10px; */
}

.signUpLink:hover {
    animation: size 0.2s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    color: #e6ccb5;
}

.monkeyInfo {
    text-align: center;
    color: white;
    padding: 0 1%;
    font-size: clamp(0.9rem, calc(2vw + 3px), 1.2rem);
    font-family: Arial, Helvetica, sans-serif;
}
.monkeyInfo2 {
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 100;
    text-align: center;
}

.heading {
    color: white;
    text-align: center;
    margin-top: 12px;    
    font-weight: 400;
    text-align: center;
}

#dotFlex {
    display: flex;
    justify-content: center;
    flex-direction: column;
    color: white;
    text-align: center;
    list-style-position: inside;
    font-size: clamp(0.9rem, calc(2vw + 6px), 1.1rem);
    padding: 0 1%;
}

#dotFlex li:nth-child(2) {
    margin-top: 10px;
}

#qrCode {
    display: block;
    margin: 20px auto 0;
    width: 10%;
    min-width: 120px;
}

#monkeyBody {
    padding: 0 0 90px;
    margin: 0 10%;
    
    border-radius: min(2.5vw, 10px);
    margin-bottom: 2vw;
    
}

#ImageParent {
    text-align: center;
    margin-top: 5rem;
}

#posterImage {
    display: block;
    margin: 20px auto 15px;
    width: 70vw;
    min-width: 300px;
    max-width: 1000px;
    box-shadow:  10px 10px 10px  0px rgba(117, 46, 145, 0.692);
    border: white solid 10px;
    border-radius: 5px;
}

#gangImage {
    position: absolute;
    display: block;
    width: clamp(12rem, calc(20vw + 6rem), 26rem);
    z-index: -1;
    right: 0px;
    bottom: 38px;
    rotate: 0deg;
    overflow: hidden;
    filter: saturate(70%);
    
}

#catFishImage {
    position: absolute;
    display: block;
    width: clamp(8rem, calc(14vw + 5rem), 18rem);
    z-index: -1;
    left: 4px;
    bottom: 0px;
    filter: saturate(70%);
}

#volcanoImage {
    position: absolute;
    display: block;
    width: max(310px, 100vw);
    object-fit: cover;
    max-height: 100vh;
    min-height: 50vh;
    z-index: -3;
    right: 0px;
    bottom: 0px;
    filter: saturate(70%) blur(4px);
}

.indexText {
    padding: 15px;
    margin-top: 2rem;
    background-color: rgba(255, 255, 255, 0.096);
    border-radius: min(2.5vw, 10px);
    color: white;
    margin-left: 15%;
    margin-right: 15%;
    margin-bottom: 10px;
    text-wrap: pretty;
}

@keyframes size {
	0% {
		transform: scale(1);
	}
    100%{
        transform: scale(1.1);
    }
}

@media only screen and (max-width: 830px) {
    #monkeyBody {
        margin: 0;
        margin-bottom: 12vw;
    }

    .monkeyInfo { 
        margin-left: 10%;
        margin-right: 10%;
    }

    #gangImage {
        right: 0px;
        rotate: 0deg;
        display: none;
        
    }

    #catFishImage {
        left: -20px;
        display: none;
    }

    #volcanoImage {
        bottom: min(80px, calc(10vw + 40px));
    }
}


