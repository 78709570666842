@font-face {
    font-family: 'ClashDisplay-Semibold';
    src: url('../Fonts/ClashDisplay/ClashDisplay-Semibold.otf');
    font-weight: 700;
    font-display: swap;
    font-style: normal;
  }

h2 {
    position: relative;
    bottom: 10px;
    color: white;
}

.header {
    color: white;
    text-align: center;
    margin-top: 5px;
    font-weight: bolder;
}

.container {
    margin-top: clamp(50px, 7vw, 70px);
    position: relative;
    height: calc(6rem + 3vw);
    display: block;
    color: rgba(70, 70, 235, 0.603);
    background-color: rgba(70, 70, 235, 0.562);
}

.link {
    color: white;
    font-style: oblique;
    font-weight: 500;
    transition: 0.2s;
}

.link:hover{
    transform: scale(1.03);
    color: #e6ccb5;
}

p {
    font-size: clamp(1rem, calc(2.2vw), 1.2rem);
}

#monkey {
    display: block;
    width: 50vw;
    aspect-ratio: 3/2;
    min-width: 300px;
    max-width: 650px;
    margin: 0 auto;
    border-radius: clamp(39px, 6.5vw, 81px);
    object-fit: cover;
    box-shadow:  10px 10px 10px  0px rgb(143, 46, 145);
    border: white solid 10px;
}

#monkeyText {
    position: absolute;
    display: block;
    left: 50%;
    transform: translateX(-50%);
    bottom: 1rem;
    width: calc(10rem + 12vw);
    min-width: 14rem;
    max-width: 18rem;
}

#monkeyGang {
    position: absolute;
    display: block;
    right: 0rem;
    bottom: 0rem;
    width: calc(8rem + 9vw);
    min-width: 10rem;
    max-width: 18rem;
    
}

#qr {
    display: block;
    margin: 20px auto;
    width: 10%;
    min-width: 120px;
}

#eventInfo {
    margin: 50px 0;
    margin-top: clamp(20px, calc(5vw), 50px);
    padding: 0 10% 30px;
}

.infoBlock {
    display: flex;
    justify-content: space-between;
    gap: clamp(10px, 3vw, 30px);
    align-items: center;
    padding: 15px 1%;
}

.infoText {
    text-align: center;
    width: 45vw;
    min-width: 150px;
    max-width: 800px;
    font-size: clamp(0.8rem, calc(2vw), 1.4rem);
    line-height: 1.2;
}

.infoImg {
    width: 35vw;
    min-width: 100px;
    max-width: 300px;
    color: white;
}

.indexText {
    padding:  25px 15px 15px;
    margin-bottom: 120px;
    margin-top: 2rem;
    background-color: rgba(255, 255, 255, 0.096);
    border-radius: min(2.5vw, 10px);
    color: white;
    margin-left: 15%;
    margin-right: 15%;
    text-wrap: pretty;
}

.innerText{
    margin-left: 5%;
    margin-right: 5%;
    font-weight: 100;
    font-family: Arial, Helvetica, sans-serif;
    text-align: center;
}

.innerHead{
    text-align: center;
    font-weight: 400;
}

.signOff {
    font-style: italic;
    font-size: large;
    margin-bottom: 10px;
}

#scrollContainer {
    margin: 0;
    position: relative;
    background-color: rgb(226, 122, 84);
    overflow: hidden;
    width: 100%;
    color: white;

}

.linkScrolling {
    overflow: hidden;
    height: 100%;
    white-space: nowrap;
    animation: scrollLeft 12s infinite linear;
    margin: 0;
    font-size: 0;
    display: inline-block;
}

span {
    overflow: hidden;
    font-size: 26px;
    display: inline-block;
    width: 100%;
    margin-top: 6px;
    padding: 1;
    color: rgb(70, 70, 235);
    font-family: monospace;
    font-weight: bold;
    letter-spacing: 4px;
    font-family: ClashDisplay-Semibold;
}

.imageButton {
    width: 20%;
    
}

.imageButtonContainer{
    margin-top: 2rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

#posterImage {
    display: block;
    margin: 20px auto 15px;
    width: 70vw;
    min-width: 300px;
    max-width: 1000px;
    box-shadow:  10px 10px 10px  0px rgba(117, 46, 145, 0.692);
    border: white solid 10px;
    border-radius: 5px;
}

span:hover{
    color: rgb(88, 113, 223);
}


@keyframes scrollLeft {
    from {
        transform: translatex(0%);
    }

    to {
        transform: translate(-25%);
    }
}

/*https://stackoverflow.com/questions/71355574/smooth-animation-loop-for-horizontal-text-scroll*/

@media only screen and (max-width: 400px) {
    #eventInfo {
        padding: 0 20px 30px;
    }
}

@media only screen and (max-width: 830px) { 
    .indexText {
        margin-left: 7%;
        margin-right: 7%;
        padding: 10px;
    }

    #monkeyText {
        position: absolute;
        display: block;
        left: clamp(30%, calc(20% + 12vw), 50%);
        transform: translateX(-50%);
        bottom: 1.5rem;
        width: calc(10rem + 10vw);
        min-width: 11rem;
        max-width: 18rem;
    }
}
